import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import ThemeChange from "../components/theme-change/theme-change.component";
import Footer from "../container/footer/footer";
import Header from "../container/header/header";
import { setCurrentUser } from "../redux/user/user.action";
import { getLatestAcceptedUserTos } from "../services/dashboards/users.service";

function DefaultLayout() {
	// Globally set the intro modal
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);

	const checkLatestUserTos = async () => {
		try {
			if (user.id) {
				const res = await getLatestAcceptedUserTos({ user_id: user.id });
				if (res.data === 1) {
					dispatch(setCurrentUser({ ...user, tosAcknowledged: 1 }));
				} else {
					dispatch(setCurrentUser({ ...user, tosAcknowledged: 0 }));
				}
			}
		} catch (error) {
			console.error("error", error);
		}
	};

	useEffect(() => {
		if (user.id) {
			checkLatestUserTos();
		}
	}, [user.id]);

	return (
		<div className='App'>
			<ThemeChange />
			<div className='site-content' id='SiteContent'>
				<Header />
				<div className='site-inner-content'>
					<Outlet />
				</div>
				<Footer />
			</div>
		</div>
	);
}

export default DefaultLayout;
