import { axiosPost } from "../../../axios/config";
import { geocodeAutocomplete } from "./townson-dashboard-data";

const API_KEY = import.meta.env.VITE_REACT_APP_TOMTOM_API_KEY || "";
const BASE_URL = "api.tomtom.com";
const BACKEND_URL = import.meta.env.VITE_REACT_APP_API_URL;
const VERSION = 2;
const EXT = "json";
const country = "US";

export const getGeocode = async (query) => {
	try {
		const results = await geocodeAutocomplete({
			query,
		});
		return results;
	} catch (e) {
		return e;
	}
};

export const getBatchGeocode = async (query) => {
	try {
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(query),
		};
		const data = await fetch(
			`https://${BASE_URL}/search/${VERSION}/batch/sync.${EXT}?countrySet=${country}&key=${API_KEY}`,
			requestOptions,
		);

		return data.json();
	} catch (e) {
		return e;
	}
};

export const getReverseGeocode = async (lat, lon) => {
	try {
		const data = await fetch(
			`https://${BASE_URL}/search/${VERSION}/reverseGeocode/${lat},${lon}.${EXT}?key=${API_KEY}`,
		);

		return data.json();
	} catch (e) {
		return e;
	}
};

export const getBackendGeocode = async (data) => {
	const response = await axiosPost(`${BACKEND_URL}/api/v2/drive-times/geocode-locations`, data);
	return response;
};
