import React, { useEffect, useState } from "react";

import { getGeocode } from "../../../../services/dashboards/townson-dashboard.services/geocode.service";
import { checkArrayHasNullValue, useDebounce } from "../../../../utils/constants";

function SelectWithSearchBox(props) {
	const [searchValue, setSearchValue] = useState("");
	const [haseFocus, setHasFocus] = useState(false);
	const [options, setOptions] = useState([]);

	const debouncedSearchLimitTerm = useDebounce(searchValue, 500);

	useEffect(() => {
		if (searchValue !== "") {
			setOptions([]);
			getSearchData();
		} else {
			if (props.selectLocationHistory) {
				setOptions(props.selectLocationHistory);
			}
		}
		// eslint-disable-next-line
	}, [debouncedSearchLimitTerm]);

	useEffect(() => {
		if (props.selectLocationHistory) {
			setOptions(props.selectLocationHistory);
		}
	}, [props.selectLocationHistory]);

	useEffect(() => {
		setSearchValue("");
	}, [props.resetSearchValue]);

	const getSearchData = async () => {
		try {
			const res = await getGeocode(searchValue);

			let tmpData = res?.results;
			if (
				props.selectedProposedLocation &&
				checkArrayHasNullValue(props?.selectedProposedLocation) === false &&
				res?.results.length > 0
			) {
				tmpData = await res?.results.filter((item) => {
					const tmp = props.selectedProposedLocation.filter((location) => {
						return location?.address?.freeformAddress === item?.address?.freeformAddress;
					});
					if (tmp.length === 0) {
						return item;
					}
				});
			}
			const tmpData2 = await res?.results;
			const { results } = res;
			const [firstResult] = results;

			const {
				address: {
					streetNumber,
					streetName,
					localName,
					postalCode,
					countrySubdivisionName,
					countrySubdivision,
				},
			} = firstResult;

			const receivedValue1 = `${streetNumber} ${streetName},${localName}, ${countrySubdivision} ${postalCode}`;
			const receivedValue2 = `${streetNumber} ${streetName},${localName}, ${countrySubdivisionName} ${postalCode}`;

			if (
				searchValue.replace(/\s/g, "").toLowerCase() === receivedValue1.replace(/\s/g, "").toLowerCase() ||
				searchValue.replace(/\s/g, "").toLowerCase() === receivedValue2.replace(/\s/g, "").toLowerCase()
			) {
				setSearchValue(tmpData2[0]?.address?.freeformAddress);
				props.setSelectedItem(tmpData2[0]);
			}

			setOptions(tmpData);
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		document.body.addEventListener(
			"click",
			(e) => {
				if (
					!e.target.classList.contains("select-item") &&
					!e.target.classList.contains("select-search-input")
				) {
					const classData = document.getElementsByClassName("select-items-wrapper");
					if (classData.length > 0) {
						for (const item of classData) {
							if (item.classList.contains("show")) {
								setHasFocus(false);
							}
						}
					}
				}
			},
			true,
		);
	}, []);

	const selectClass = (e, value) => {
		e.preventDefault();
		if (value) {
			props.setSelectedItem(value);
			setSearchValue(value?.address?.freeformAddress);
		}
	};

	// select-trigger-wrap hasFocus
	return (
		<>
			<div className='input-item'>
				<div>
					<input
						autoComplete='off'
						id='header-search-id'
						type='text'
						value={
							props.selectedProposedLocation && props.selectedProposedLocation[props.index]
								? props.selectedProposedLocation[props.index]?.address?.freeformAddress
								: props?.selectedLocation?.address
								? props?.selectedLocation?.address?.freeformAddress
								: searchValue
						}
						className={
							"select-trigger-wrap" +
							(haseFocus ||
							searchValue ||
							props.placeholder ||
							props?.selectedLocation?.address?.freeformAddress ||
							(props.selectedProposedLocation &&
								props.selectedProposedLocation[props.index] &&
								props.selectedProposedLocation[props.index]?.address)
								? " hasFocus"
								: "")
						}
						onClick={() => setHasFocus(true)}
						onChange={(e) => setSearchValue(e.target.value)}
						onKeyUp={() => {
							props.setSelectedItem(null);
						}}
						placeholder={props.placeholder ? props.placeholder : ""}
					/>
					<label className=''>{props.lableName}</label>
					<div
						className={`select-items-wrapper ${haseFocus === true ? "show" : ""}`}
						id={`dropdown-${props.lableName}`}
					>
						<ul className='select-items-wrap'>
							{options?.length > 0 &&
								options.map((item, index) => (
									<li
										id={`${
											item?.address?.postalCode
												? `address_${item?.address?.postalCode}`
												: `${index}_header_address`
										}`}
										className={`select-item ${
											searchValue === item.address.freeformAddress ? "active" : ""
										}`}
										key={index}
										onClick={(e) => {
											selectClass(e, item);
											setHasFocus(false);
										}}
									>
										<span>{item.address.freeformAddress} </span>
									</li>
								))}
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}

export default SelectWithSearchBox;
