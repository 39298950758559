// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";

export const driveTimeOptions = [
	{
		id: 15,
		name: "15 Minutes",
	},
	{
		id: 30,
		name: "30 Minutes",
	},
	{
		id: 45,
		name: "45 Minutes",
	},
	{
		id: 60,
		name: "60 Minutes",
	},
];

export const visitOverTimeOptions = [
	{
		id: "day",
		name: "Day",
	},
	{
		id: "month",
		name: "Month",
	},
	{
		id: "quarter",
		name: "Quarter",
	},
	{
		id: "year",
		name: "Year",
	},
];

export const randomColors = [
	"#008FFB",
	"#FF4560",
	"#00E396",
	"#FEB019",
	"#775DD0",
	"#546E7A",
	"#26a69a",
	"#D10CE8",
	"#FFA21D",
];

export const mosaicClusterColors = {
	"Group A Power Elite": "#581496",
	"Group B Flourishing Families": "#9965FC",
	"Group C Booming with Confidence": "#BE26B9",
	"Group D Suburban Style": "#FF66FC",
	"Group E Thriving Boomers": "#A9A6D3",
	"Group F Promising Families": "#013899",
	"Group G Young City Solos": "#FE2905",
	"Group H Bourgeois Melting Pot": "#ED3F76",
	"Group I Family Union": "#F79134",
	"Group J Autumn Years": "#C82056",
	"Group K Significant Singles": "#00D8AD",
	"Group L Blue Sky Boomers": "#2D652B",
	"Group M Families in Motion": "#46D026",
	"Group N Pastoral Pride": "#4393C3",
	"Group O Singles and Starters": "#7F7F7F",
	"Group P Cultural Connections": "#FBFA5F",
	"Group Q Golden Year Guardians": "#6D1B1C",
	"Group R Aspirational Fusion": "#D5C925",
	"Group S Thrifty Habits": "#897C16",
	"Group U Unclassified": "#E3E4AA",
};

export const mosaicClusterPdf = {
	"Group A Power Elite": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupA_DescPortrait.pdf",
	"Group B Flourishing Families": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupB_DescPortrait.pdf",
	"Group C Booming with Confidence": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupC_DescPortrait.pdf",
	"Group D Suburban Style": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupD_DescPortrait.pdf",
	"Group E Thriving Boomers": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupE_DescPortrait.pdf",
	"Group F Promising Families": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupF_DescPortrait.pdf",
	"Group G Young City Solos": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupG_DescPortrait.pdf",
	"Group H Bourgeois Melting Pot": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupH_DescPortrait.pdf",
	"Group I Family Union": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupI_DescPortrait.pdf",
	"Group J Autumn Years": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupJ_DescPortrait.pdf",
	"Group K Significant Singles": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupK_DescPortrait.pdf",
	"Group L Blue Sky Boomers": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupL_DescPortrait.pdf",
	"Group M Families in Motion": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupM_DescPortrait.pdf",
	"Group N Pastoral Pride": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupN_DescPortrait.pdf",
	"Group O Singles and Starters": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupO_DescPortrait.pdf",
	"Group P Cultural Connections": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupP_DescPortrait.pdf",
	"Group Q Golden Year Guardians": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupQ_DescPortrait.pdf",
	"Group R Aspirational Fusion": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupR_DescPortrait.pdf",
	"Group S Thrifty Habits": "https://assets.cengage.com/gale/help/dnow/Mosaic/MosaicGroupS_DescPortrait.pdf",
	// eslint-disable-next-line no-script-url
	"Group U Unclassified": "javascript:void(0)",
};

export const SOCCodeCOlors = [
	"rgb(255,255,204)",
	"rgb(255,237,160)",
	"rgb(254,217,118)",
	"rgb(254,178,76)",
	"rgb(253,141,60)",
	"rgb(252,78,42)",
	"rgb(227,26,28)",
	"rgb(189,0,38)",
	"rgb(128,0,38)",
];
export const SOCCodeBlueCOlors = ["#d7f9fa", "#b6e7fa", "#6ebbff", "#4f8df7"];

export const IncentiveTypeTrendsCategories = ["#5c86c1", "#3fb7f3", "#81cab2", "#8b79d5", "#BA5ABB", "#CFE1A2"];

export const scoreBreakdownCategories = ["#5c86c1", "#655cc1"];

export const barChartColors = [
	"#5C86C1",
	"#5C86C1",
	"#5C86C1",
	"#5C86C1",
	"#5C86C1",
	"#5C86C1",
	"#5C86C1",
	"#5C86C1",
	"#5C86C1",
	"#3FB7F3",
	"#3FB7F3",
	"#3FB7F3",
	"#3FB7F3",
	"#3FB7F3",
	"#81CAB2",
	"#81CAB2",
	"#81CAB2",
	"#81CAB2",
];

export const WhoLivesNearbyChartColors = [
	"#581496",
	"#581496",
	"#581496",
	"#581496",
	"#581496",
	"#581496",
	"#9965FC",
	"#9965FC",
	"#9965FC",
	"#9965FC",
	"#BE26B9",
	"#BE26B9",
	"#BE26B9",
	"#BE26B9",
	"#FF66FC",
	"#FF66FC",
	"#FF66FC",
	"#FF66FC",
	"#A9A6D3",
	"#A9A6D3",
	"#A9A6D3",
	"#013899",
	"#013899",
	"#FE2905",
	"#FE2905",
	"#ED3F76",
	"#ED3F76",
	"#ED3F76",
	"#ED3F76",
	"#F79134",
	"#F79134",
	"#F79134",
	"#F79134",
	"#C82056",
	"#C82056",
	"#C82056",
	"#00D8AD",
	"#00D8AD",
	"#00D8AD",
	"#00D8AD",
	"#2D652B",
	"#2D652B",
	"#2D652B",
	"#46D026",
	"#46D026",
	"#4393C3",
	"#4393C3",
	"#4393C3",
	"#4393C3",
	"#7F7F7F",
	"#7F7F7F",
	"#7F7F7F",
	"#7F7F7F",
	"#7F7F7F",
	"#7F7F7F",
	"#FBFA5F",
	"#FBFA5F",
	"#FBFA5F",
	"#FBFA5F",
	"#FBFA5F",
	"#FBFA5F",
	"#6D1B1C",
	"#6D1B1C",
	"#6D1B1C",
	"#6D1B1C",
	"#D5C925",
	"#D5C925",
	"#897C16",
	"#897C16",
	"#897C16",
	"#897C16",
];

export const nationalInsightsStatesListColor = [
	"rgba(92, 134, 193, 0.75)",
	"rgba(63, 183, 243, 0.75)",
	"rgba(129, 202, 178, 0.75)",
];

export const generalBreadcrumbsData = [
	{
		title: "Dashboards",
		link: "/dashboard",
	},
];

export const MSARankingBreadcrumbsData = [
	{
		title: "Dashboards",
		link: "/dashboard",
	},
	{
		title: "MSA Ranking",
	},
];

export const AdvancedSiteSettingBreadcrumbsData = [
	{
		title: "Dashboards",
		link: "/dashboard",
	},
	{
		title: "Advanced Site Ranking",
	},
];

export const LaborStudyHCHART3BreadcrumbsData = [
	{
		title: "Dashboards",
		link: "/dashboard",
	},
	{
		title: "Labor Study (HCHART 3)",
	},
];

/**
 * Custom hook to create a debounced value. This helps to delay updates until after a specified time period.
 *
 * @param {any} value - The value to debounce.
 * @param {number} delay - The delay time in milliseconds to wait before updating the debounced value.
 * @returns {any} - The latest debounced value.
 */
export function useDebounce(value, delay) {
	// State to store the debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		// Set a timeout to update the debounced value after the specified delay
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		// Cleanup function that clears the timeout if the value or delay changes, or on component unmount
		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]); // Re-run the effect whenever 'value' or 'delay' changes

	return debouncedValue;
}

export function checkArrayHasNullValue(array) {
	return array.join().replace(/,/g, "").length === 0;
}

export const checkValueIsPositiveDecimal = (value) => {
	return value.replace(/[^0-9.]+/g, "");
};

export const checkValueIsDecimal = (value) => {
	return value.replace(/[^0-9.]-/g, "");
};

export const convertNumberToK = (value) => {
	if (value >= 1000000) {
		value = `${(value / 1000000).toFixed(1)}M`;
	} else if (value >= 1000) {
		value = `${(value / 1000).toFixed(1)}K`;
	} else {
		value = value.toFixed(0);
	}
	return value;
};

export function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function truncateToDecimals(num, dec = 2) {
	const calcDec = 10 ** dec;
	return Math.trunc(parseFloat(num) * calcDec) / calcDec;
}

export const noDataFound = "No Data Currently Available";
export const inputFieldRequiredMessage = "Please select Location, Occupation and Drive-time";
